<template>
  <div id="home">
    <div v-if="invoice">
        <div v-if="errorMessage" class="error-banner">
        <h1 class="error">{{errorMessage}}</h1>
        </div>
        <StripePayment v-if="invoice && !successfulPayment && step == 1" v-bind:customerId="customerId" v-bind:amount="invoice.total" v-bind:invoiceNeedingPayment="invoice" @paid="successfullyPaid" />
        <div v-if="successfulPayment" class="component card">
        <div class="title">
            <h1>All set!</h1>
            <p>You successfully paid for your invoice.</p>
        </div>
        </div>
    </div>
    <div v-else>
        <h1>No bueno</h1>
    </div>
  </div>
</template>

<script>

import StripePayment from '@/components/StripePayment.vue';



export default {
  name: 'RetryInvoice',
  components: {
      StripePayment,
  },
  props: ["",],
  data: () => ({
    step: 1,
    pricing: null,
    stripeClientSecret: null,
    successfulPayment: null,
    errorMessage: null,
    upgradeInfo: null,
  }),
  mounted() {

  },
  methods: {
    onCardUpdateComplete(result) {
      this.success = true;
    },
    successfullyPaid: function () {
      this.successfulPayment = true;
    },
  },
  computed: {
    invoice: function() {
      let invoice = this.$store.state.stripeAccount.invoices.filter(invoice => invoice.id === this.$route.params.invoiceId)[0];

      if (invoice) {
          return invoice;
      } else {
          return null;
      }
    },
    customerId: function() {
      return this.$store.state.stripeAccount.id;
    },
  },
}
</script>

<style scoped lang="scss">
#payment {
  .title {
    .pricing-description {
      font-size: 1.2em;
      em {
        color: $payment-button;
        background-color:white;
        text-decoration: none;
      }
    }
  }
  p {
    margin-bottom: 1em;
    text-align: center;
    // color: white;
  }
  #payment-form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 15px 40px;
    margin: auto;
    background: rgba(100, 100, 100, 0.5);
  }

  label {
    padding:10px;
    display: block;
  }

  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    font-size: 16px;
    width: calc(100% - 24px);
    background: white;
    text-align: center;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: $red;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    margin-top: 12px;
  }
  #card-element {
    border-radius: 6px;
    padding: 22px 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    width: calc(100% - 24px);
    background: white;
    position: relative;

    div {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  button {
    background: $payment-button;
    color: #ffffff;
    font-family: Arial, sans-serif;
    border-radius: 6px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: $payment-button;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: $payment-button;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
    #payment-form {
      // width: 200px;
      min-width: 200px;
      width: auto !important;
      border-radius: 0 0 8px 8px;
    }
  }
}
</style>
